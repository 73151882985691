import { Link } from "react-router-dom"

const Home = () => {
    return (
        <ul>
            <li>
                <Link to="/vaccinee">Vaccinee</Link>
            </li>
            <li>
                <Link to="/HMO">HMO</Link>
            </li>
        </ul>
    )
};

export default Home;
