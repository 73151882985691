import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const HMO = () => (
  <form noValidate autoComplete="off">
    <TextField id="standard-basic" label="Standard" />
    <Button variant="contained" color="primary">
      Create Vaccine Contract
    </Button>
  </form>
);

export default HMO;
